<template>
  <Breadcrumb v-if="detailTitle" :detailTitle="detailTitle.toUpperCase()" />
  <Breadcrumb v-else detailTitle="&nbsp;" />
  <div class="row">
    <div class="col">
      <div class="card customobject-detail-container">
        <div class="row">
          <div class="col-xl-3 col-xxl-3">
            <div class="customobject-detail-menu">
              <ul class="list-unstyled customobject-detail-status-filter">
                <li>
                  <router-link
                    class="co-left-nav-link"
                    :class="
                      this.$route.meta.viewName === 'detail' ? 'active' : ''
                    "
                    :to="{
                      name: 'ProjectDetail',
                      params: { projectId: this.$route.params.projectId },
                    }"
                  >
                    <i class="bi bi-info-circle"></i
                    >{{
                      $t(
                        "Projects.Detail",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </router-link>
                </li>
                <li>
                  <a class="co-left-nav-link"
                    ><i class="bi bi-lightning"></i
                    >{{
                      $t(
                        "Projects.UsageInformations",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}</a
                  >
                </li>
                <li>
                  <a class="co-left-nav-link"
                    ><i class="bi bi-file-text"></i
                    >{{
                      $t(
                        "Projects.ContractInformations",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}</a
                  >
                </li>
                <li>
                  <a class="co-left-nav-link"
                    ><i class="bi bi-bug"></i
                    >{{
                      $t(
                        "Projects.FormulaErrors",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}</a
                  >
                </li>
                <li>
                  <a class="co-left-nav-link"
                    ><i class="bi bi-gear"></i
                    >{{
                      $t(
                        "Projects.Configurations",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}</a
                  >
                </li>
              </ul>
              <router-link
                class="btn btn-warning d-block m-b-lg"
                :to="{
                  name: 'ProjectEdit',
                  params: { projectId: this.$route.params.projectId },
                }"
              >
                <i class="bi bi-pencil-square"></i>
                {{
                  $t(
                    "Buttons.Edit",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </router-link>
            </div>
          </div>
          <div class="col-xl-9 col-xxl-9">
            <div class="customobject-detail">
              <router-view></router-view>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "LayoutProject",
  data() {
    return {
      detailTitle: "",
    };
  },
  methods: {
    getProjectDetail() {
      this.$prodGatewayAxios
        .get(
          String.format("/Lcdp-ArgesetGet?id={0}", this.$route.params.projectId)
        )
        .then((response) => {
          this.detailTitle = response.data.name;
        })
        .catch(function (error) {
          //alert(error);
        });
    },
  },
  mounted() {
    this.getProjectDetail();
  },
};
</script>
